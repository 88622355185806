import { JSX, h, VNode } from "preact";
import { SSODetailsResponse } from "../types";
import { useStore } from "../store";
import shallow from "zustand/shallow";

type SSOSignInProps = {
  details: SSODetailsResponse | null;
  onSubmit: (event: JSX.TargetedEvent<HTMLButtonElement, Event>) => void;
};

export function SSOSignIn({ details, onSubmit }: SSOSignInProps): VNode | null {
  const { error } = useStore(
    (state) => ({
      error: state.errors.ssoSignIn,
    }),
    shallow
  );
  if (!details) {
    return null;
  }

  return (
    <div>
      <strong class="title">Select your SSO provider</strong>

      <ul class="selection_container">
        <li class="selected" tabIndex={0}>
          {details.identity_provider_name}
          <div class="checkmark_container">
            <span class="checkmark" />
          </div>
        </li>
      </ul>

      <button
        name="ssoSignInSubmitButton"
        class="login_button"
        onClick={onSubmit}
        tabIndex={0}
      >
        Sign In
      </button>
      {error && <div class="error">{error}</div>}
    </div>
  );
}
