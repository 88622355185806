import "@fortawesome/fontawesome-svg-core/styles";
import "./assets/fonts/fonts.css";
import "./style";
import { useCallback, useEffect, useState } from "preact/hooks";
import { JSX, h, VNode } from "preact";
import shallow from "zustand/shallow";
import { AuthenticateResponse } from "./types";
import { SignIn } from "./screens/SignIn";
import { RequestPasswordReset } from "./screens/RequestPasswordReset";
import { PasswordReset } from "./screens/PasswordReset";
import { NewPasswordChallenge } from "./screens/NewPasswordChallenge";
import { ChooseTenant } from "./screens/ChooseTenant";
import { SSOAuthenticate } from "./screens/SSOAuthenticate";
import { SSODetails } from "./screens/SSODetails";
import { SSOSignIn } from "./screens/SSOSignIn";
import { Footer } from "./components/Footer";
import { useStore } from "./store";
import { UserSettings } from "./screens/UserSettings";
import { getCSRFToken, clearCSRFToken } from "./utils";
import { SSOInProgress } from "./screens/SSOInProgress";

export default function App(): VNode {
  const {
    activeScreen,
    isInFrame,
    setIsInFrame,
    checkSSOIdp,
    changeScreen,
    setActiveScreen,
    setError,
    setProcessing,
    setResponse,
    ssoAuthenticate,
    ssoDetailsResponse,
    clearStore,
    successMessage,
  } = useStore(
    (state) => ({
      activeScreen: state.activeScreen,
      isInFrame: state.isInFrame,
      setIsInFrame: state.setIsInFrame,
      checkSSOIdp: state.checkSSOIdp,
      changeScreen: state.changeScreen,
      setActiveScreen: state.setActiveScreen,
      setError: state.setError,
      setProcessing: state.setProcessing,
      setResponse: state.setResponse,
      ssoAuthenticate: state.ssoAuthenticate,
      ssoDetailsResponse: state.responses.ssoCheck,
      clearStore: state.clearStore,
      successMessage: state.successMessages.app,
    }),
    shallow
  );
  const [sessionCheckInit, setSessionCheckInit] = useState({
    check_done: false,
    sso_complete_expected: false,
  });

  const redirectToAizait = useCallback(
    (redirectUrl: string, envAndTenantId: string) => {
      async function fetch_authentication_key() {
        const env_and_tenant_id = encodeURIComponent(envAndTenantId);
        const response = await fetch(
          `/authentication_key?env_and_tenant_id=${env_and_tenant_id}`,
          {
            method: "GET",
            headers: {
              "X-CSRF-Token": getCSRFToken(),
            },
          }
        );
        if (response.ok) {
          const authentication_key = await response.json();
          window.location.href = `${redirectUrl}#authentication_key=${authentication_key}`;
        } else {
          let message;
          if (response.status == 401) {
            message = "Session expired";
          } else {
            message = "Internal error";
          }
          setError("signIn", message);
          setActiveScreen("index");
          setSessionCheckInit({
            check_done: false,
            sso_complete_expected: false,
          });
          clearCSRFToken();
        }
      }
      fetch_authentication_key().then();
    },
    [setError, setActiveScreen, setSessionCheckInit]
  );

  const redirectToUserSettings = useCallback(() => {
    setActiveScreen("user_settings");
  }, [setActiveScreen]);

  const redirectToChooseTenant = useCallback(() => {
    window.history.replaceState({}, "", window.location.pathname);
    setActiveScreen("choose_tenant");
  }, [setActiveScreen]);

  const logout = useCallback(() => {
    fetch("/session_logout", {
      method: "POST",
      headers: {
        "X-CSRF-Token": getCSRFToken(),
      },
    }).then(() => {
      clearStore();
      clearCSRFToken();
      // Make sure we go to a clean login page
      window.history.replaceState({}, "", window.location.pathname);
      setSessionCheckInit({
        check_done: false,
        sso_complete_expected: false,
      });
      setActiveScreen("index");
    });
  }, [setActiveScreen, clearStore]);

  const onSSOSignIn = (event: JSX.TargetedEvent<HTMLButtonElement, Event>) => {
    event.preventDefault();
    if (ssoDetailsResponse) {
      const {
        idm_url,
        state,
        code_challenge,
        code_challenge_method,
        identity_provider_id,
      } = ssoDetailsResponse;
      setError("ssoSignIn", "");
      if (isInFrame) {
        const redirect_uri = `${encodeURIComponent(
          document.location.origin
        )}/?in_frame=true`;
        const sso_login_uri = `${idm_url}?response_type=code&client_id=aiwo-login&state=${state}&scope=openid&redirect_uri=${redirect_uri}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&kc_idp_hint=${identity_provider_id}`;
        const width = 620;
        const height = 600;
        const x = window.screenX; // window.innerWidth / 2 - width / 2;
        const y = window.screenY + window.innerHeight / 2 - height / 2;
        const win = open(
          sso_login_uri,
          "Login",
          `width=${width},height=${height}, left=${x},top=${y}`
        );
        if (win) {
          setActiveScreen("sso_in_progress");
          const timer = setInterval(function () {
            if (win.closed) {
              clearInterval(timer);
              setSessionCheckInit({
                check_done: false,
                sso_complete_expected: true,
              });
            }
          }, 1000);
        }
      } else {
        const redirect_uri = `${encodeURIComponent(document.location.origin)}/`;
        const sso_login_uri = `${idm_url}?response_type=code&client_id=aiwo-login&state=${state}&scope=openid&redirect_uri=${redirect_uri}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&kc_idp_hint=${identity_provider_id}`;
        window.location.href = sso_login_uri;
      }
    }
  };

  const onIndexNavigation = (
    event: JSX.TargetedEvent<HTMLAnchorElement, Event>
  ) => {
    const params = new URLSearchParams(window.location.search);
    params.delete("code");
    params.delete("session_state");
    params.delete("state");
    window.history.replaceState(
      {},
      "",
      params.toString() !== ""
        ? `${window.location.pathname}?${params.toString()}`
        : window.location.pathname
    );
    setError("signIn", "");
    changeScreen("index")(event);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const session_state = params.get("session_state");
    const state = params.get("state");
    const route = params.get("route");
    const sso_idp = params.get("idp");
    const in_frame = params.get("in_frame") === "true";

    async function check_session(sso_auth_complete_expected: boolean) {
      const refresh_tenants = sso_auth_complete_expected ? "false" : "true";
      const response = await fetch(
        `/session?refresh_tenants=${refresh_tenants}`,
        {
          method: "GET",
          headers: {
            "X-CSRF-Token": getCSRFToken(),
          },
        }
      );
      if (response.ok) {
        const auth_response: AuthenticateResponse = await response.json();
        const { tenants, authentication_complete } = auth_response;
        setResponse("authentication", {
          tenants,
          authenticationComplete: authentication_complete,
          passwordChanged: false,
        });
        if (authentication_complete) {
          setActiveScreen("choose_tenant");
        } else if (sso_idp !== null) {
          await checkSSOIdp(sso_idp);
        } else if (sso_auth_complete_expected) {
          setError("ssoSignIn", "SSO authentication failed");
          setActiveScreen("sso_signin");
        } else {
          setActiveScreen("index");
        }
      } else if (sso_auth_complete_expected) {
        setError("ssoSignIn", "SSO authentication failed");
        setActiveScreen("sso_signin");
      } else {
        setActiveScreen("index");
      }

      if (route === "user-settings") {
        setActiveScreen("user_settings");
      }
    }

    if (code !== null && session_state !== null && state !== null) {
      setProcessing("signIn", true);
      setActiveScreen("sso_authenticate");
      ssoAuthenticate(code, session_state, state, in_frame);
      window.history.replaceState({}, "", window.location.pathname);
    } else if (!sessionCheckInit.check_done) {
      const sso_expected_complete = sessionCheckInit.sso_complete_expected;
      setIsInFrame(window.self !== window.top);
      setSessionCheckInit({
        check_done: true,
        sso_complete_expected: false,
      });
      check_session(sso_expected_complete);
    }
  }, [
    checkSSOIdp,
    setActiveScreen,
    setError,
    setIsInFrame,
    setProcessing,
    ssoAuthenticate,
    sessionCheckInit,
    setResponse,
  ]);
  return (
    <div class="container">
      <div class={activeScreen === "user_settings" ? "form-wide" : "form"}>
        <div class="logo-container">
          <img src="assets/logo.svg" class="logo" />
          <img src="assets/logo_dark.svg" class="logo_dark" />

          {activeScreen === "index" && successMessage && (
            <div class="success">{successMessage}</div>
          )}
        </div>

        {activeScreen === "index" && <SignIn />}
        {activeScreen === "user_settings" && (
          <UserSettings redirectToChooseTenant={redirectToChooseTenant} />
        )}
        {activeScreen === "request_password_reset" && <RequestPasswordReset />}
        {activeScreen === "password_reset" && <PasswordReset />}
        {activeScreen === "new_password_challenge" && <NewPasswordChallenge />}
        {activeScreen === "choose_tenant" && (
          <ChooseTenant
            redirectToAizait={redirectToAizait}
            redirectToUserSettings={redirectToUserSettings}
            logout={logout}
          />
        )}
        {activeScreen === "sso_authenticate" && (
          <SSOAuthenticate onIndexNavigation={onIndexNavigation} />
        )}
        {activeScreen === "sso_details" && <SSODetails />}
        {activeScreen === "sso_signin" && (
          <SSOSignIn onSubmit={onSSOSignIn} details={ssoDetailsResponse} />
        )}
        {activeScreen === "sso_in_progress" && <SSOInProgress />}
      </div>
      <Footer />
    </div>
  );
}
