import { useEffect, useRef, useState } from "preact/hooks";
import { h, VNode } from "preact";
import shallow from "zustand/shallow";
import { useStore } from "../store";
import PasswordStrengthBar from "../components/async/PasswordStrengthBar";

export function NewPasswordChallenge(): VNode {
  const { error, processing, setError, setPermanentPassword } = useStore(
    (state) => ({
      error: state.errors.permanentPassword,
      processing: state.processing.permanentPassword,
      setError: state.setError,
      setPermanentPassword: state.setPermanentPassword,
    }),
    shallow
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");

  const passwordsMatch = newPassword === verifyNewPassword;
  const passwordTooShort = newPassword.length < 12 && newPassword.length > 0;
  const passwordTooLong = newPassword.length > 128;
  const newPasswordInputsAreEmpty =
    newPassword === "" && verifyNewPassword === "";

  return (
    <form
      action="/authenticate_with_challenge"
      onSubmit={setPermanentPassword({ newPassword })}
    >
      <strong class="title">Welcome to Aiwo</strong>
      <p>
        You have logged in using a temporary password and password must be
        changed.
      </p>
      <p>
        <b>Please set your own new password below.</b>
      </p>
      <p>
        Password must be at least 12 characters long, but cannot be longer that
        128 characters.
      </p>

      <div class="input">
        <input
          name="password"
          type="password"
          placeholder="New password"
          required={true}
          autocomplete="off"
          ref={inputRef}
          value={newPassword}
          onInput={(event) => setNewPassword(event.currentTarget.value)}
          onKeyUp={() => setError("permanentPassword", null)}
        />
      </div>

      <div class="input">
        <input
          name="verify_password"
          type="password"
          placeholder="New password again"
          required={true}
          autocomplete="off"
          value={verifyNewPassword}
          onInput={(event) => setVerifyNewPassword(event.currentTarget.value)}
          onKeyUp={() => setError("permanentPassword", null)}
        />
      </div>

      <PasswordStrengthBar password={newPassword} minLength={12} />

      <button
        class="login_button"
        disabled={
          newPasswordInputsAreEmpty ||
          !passwordsMatch ||
          passwordTooShort ||
          passwordTooLong ||
          processing
        }
      >
        Change password {processing && <span class="loader ml-5" />}
      </button>

      {/* @todo: Re-implement checks better when adding translation functionality */}

      {/* Backend checks */}
      {error && (
        <div class="error">
          {error === "PASSWORD_BREACHED" && (
            <span>
              Your new password isn't secure. It has been part of a known data
              breach and if you use the same password in any other service, it
              should be changed immediately. Read more about breached passwords
              from{" "}
              <a
                href="https://haveibeenpwned.com/FAQs"
                target="_blank"
                rel="noreferrer"
              >
                Have I Been Pwned
              </a>
            </span>
          )}
          {error === "PASSWORD_TOO_SHORT_OR_LONG" && (
            <span>Your new password is too short or too long!</span>
          )}
          {error === "INVALID_CURRENT_PASSWORD" && (
            <span>Your current password is not valid!</span>
          )}

          {[
            "PASSWORD_BREACHED",
            "PASSWORD_TOO_SHORT_OR_LONG",
            "INVALID_CURRENT_PASSWORD",
          ].indexOf(error) < 0 && <span>{error}</span>}
        </div>
      )}

      {/* Frontend checks */}
      {verifyNewPassword.length > 0 &&
        !newPasswordInputsAreEmpty &&
        !passwordsMatch && (
          <div class="error">
            <strong>New password</strong> and{" "}
            <strong>New password again</strong> fields do not match.
          </div>
        )}
      {passwordTooShort && passwordsMatch && (
        <div class="error">Password is too short</div>
      )}
      {passwordTooLong && passwordsMatch && (
        <div class="error">Password is too long</div>
      )}
    </form>
  );
}
