import { useEffect, useRef, useState } from "preact/hooks";
import { h, VNode } from "preact";
import shallow from "zustand/shallow";
import { useStore } from "../store";

export function RequestPasswordReset(): VNode {
  const { changeScreen, error, processing, requestPasswordReset, setError } =
    useStore(
      (state) => ({
        changeScreen: state.changeScreen,
        error: state.errors.requestPasswordReset,
        processing: state.processing.requestPasswordReset,
        requestPasswordReset: state.requestPasswordReset,
        setError: state.setError,
      }),
      shallow
    );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const [username, setUsername] = useState("");

  return (
    <form
      action="/request_password_reset"
      onSubmit={requestPasswordReset({ username })}
    >
      <strong class="title">Forgot your password?</strong>
      <p>
        Enter your email address below and we will send you a confirmation code
        for changing password.
      </p>

      <div class="input">
        <input
          name="username"
          type="text"
          placeholder="Email address"
          required={true}
          autocomplete="off"
          ref={inputRef}
          onKeyUp={() => setError("requestPasswordReset", null)}
          value={username}
          onInput={(event) => setUsername(event.currentTarget.value)}
        />
      </div>

      <button class="login_button" disabled={processing}>
        Send confirmation code {processing && <span class="loader ml-5" />}
      </button>
      {error && <div class="error">{error}</div>}

      <a href="" class="change_screen_link" onClick={changeScreen("index")}>
        Back to sign in
      </a>
    </form>
  );
}
