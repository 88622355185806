import { useEffect, useRef, useState } from "preact/hooks";
import { h, VNode } from "preact";
import PasswordStrengthBar from "../components/async/PasswordStrengthBar";
import shallow from "zustand/shallow";
import { useStore } from "../store";

export function PasswordReset(): VNode {
  const { changeScreen, error, processing, resetPassword, setError } = useStore(
    (state) => ({
      changeScreen: state.changeScreen,
      error: state.errors.resetPassword,
      processing: state.processing.resetPassword,
      resetPassword: state.resetPassword,
      setError: state.setError,
    }),
    shallow
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const passwordsMatch = newPassword === verifyNewPassword;
  const passwordTooShort = newPassword.length < 12 && newPassword.length > 0;
  const passwordTooLong = newPassword.length > 128;

  return (
    <form
      action="/reset_password"
      onSubmit={resetPassword({ newPassword, confirmationCode })}
    >
      <strong class="title">Change password</strong>
      <p>
        Please enter confirmation code from email or SMS and your new password.
      </p>
      <p>
        Password must be at least 12 characters long, but cannot be longer that
        128 characters.
      </p>

      <div class="input">
        <input
          name="confirmation_code"
          type="text"
          placeholder="Confirmation code from email or sms"
          required={true}
          autocomplete="off"
          value={confirmationCode}
          onInput={(event) => setConfirmationCode(event.currentTarget.value)}
          onKeyUp={() => setError("resetPassword", null)}
          ref={inputRef}
        />
      </div>

      <div class="input">
        <input
          name="password"
          type="password"
          placeholder="New password"
          required={true}
          autocomplete="new-password"
          value={newPassword}
          onInput={(event) => setNewPassword(event.currentTarget.value)}
          onKeyUp={() => setError("resetPassword", null)}
        />
      </div>
      <PasswordStrengthBar password={newPassword} minLength={12} />

      <div class="input">
        <input
          name="verify_password"
          type="password"
          placeholder="New password again"
          required={true}
          autocomplete="new-password"
          value={verifyNewPassword}
          onInput={(event) => setVerifyNewPassword(event.currentTarget.value)}
          onKeyUp={() => setError("resetPassword", null)}
        />
      </div>

      <button class="login_button" disabled={!passwordsMatch || processing}>
        Change password {processing && <span class="loader ml-5" />}
      </button>

      {/* @todo: Re-implement checks better when adding translation functionality */}

      {/* Backend checks */}
      {error && (
        <div class="error">
          {error === "PASSWORD_BREACHED" && (
            <span>
              Your new password isn't secure. It has been part of a known data
              breach and if you use the same password in any other service, it
              should be changed immediately. Read more about breached passwords
              from{" "}
              <a
                href="https://haveibeenpwned.com/FAQs"
                target="_blank"
                rel="noreferrer"
              >
                Have I Been Pwned
              </a>
            </span>
          )}
          {error === "PASSWORD_TOO_SHORT_OR_LONG" && (
            <span>Your new password is too short or too long!</span>
          )}
          {error === "INVALID_CURRENT_PASSWORD" && (
            <span>Your current password is not valid!</span>
          )}

          {[
            "PASSWORD_BREACHED",
            "PASSWORD_TOO_SHORT_OR_LONG",
            "INVALID_CURRENT_PASSWORD",
          ].indexOf(error) < 0 && <span>{error}</span>}
        </div>
      )}

      {/* Frontend checks */}
      {!passwordsMatch &&
        newPassword.length > 0 &&
        verifyNewPassword.length > 0 && (
          <div class="error">
            <strong>New password</strong> and{" "}
            <strong>New password again</strong> fields do not match.
          </div>
        )}
      {passwordTooShort && passwordsMatch && (
        <div class="error">
          <strong>New password is too short</strong>
        </div>
      )}
      {passwordTooLong && passwordsMatch && (
        <div class="error">
          <strong>New password is too long</strong>
        </div>
      )}

      <a href="" class="change_screen_link" onClick={changeScreen("index")}>
        Back to sign in
      </a>
    </form>
  );
}
