import { h, VNode } from "preact";
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

library.add(faLinkedin);
config.autoAddCss = false;

export const Footer = (): VNode => (
  <footer>
    <div class="footer-container">
      <a
        class="privacy"
        href="https://aiwo.ai/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Statement
      </a>
      <div>
        <a href="https://aiwo.ai" target="_blank" rel="noreferrer">
          <img src="assets/logo-grayscale.svg" class="logo" />
        </a>{" "}
        <span class="copyright">© </span>
        <a class="link-brand" href="https://www.linkedin.com/company/aiwo/">
          <i>
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </i>
        </a>
      </div>
    </div>
  </footer>
);
