export function setCSRFToken(csrf_token: string) {
  localStorage.setItem("csrftoken", csrf_token);
}

export function getCSRFToken() {
  return localStorage.getItem("csrftoken") || "";
}

export function clearCSRFToken() {
  localStorage.removeItem("csrftoken");
}
