import { useEffect, useRef, useState } from "preact/hooks";
import { h, VNode } from "preact";
import shallow from "zustand/shallow";
import { useStore } from "../store";

export function SSODetails(): VNode {
  const { changeScreen, checkSSOEmail, error, processing, setError } = useStore(
    (state) => ({
      changeScreen: state.changeScreen,
      checkSSOEmail: state.checkSSOEmail,
      error: state.errors.ssoCheck,
      processing: state.processing.ssoCheck,
      setError: state.setError,
    }),
    shallow
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const [rememberEmail, setRememberEmail] = useState(
    window.localStorage.getItem("remember_sso_email") !== null
      ? window.localStorage.getItem("remember_sso_email") === "true"
      : true
  );
  const [email, setEmail] = useState(
    rememberEmail ? window.localStorage.getItem("sso_email") || "" : ""
  );

  useEffect(() => {
    window.localStorage.setItem(
      "remember_sso_email",
      JSON.stringify(rememberEmail)
    );
    if (!rememberEmail) {
      window.localStorage.setItem("sso_email", "");
    }
  }, [rememberEmail]);

  return (
    <form action="/sso_details" onSubmit={checkSSOEmail({ email })}>
      <strong class="title">Single sign-on</strong>
      <p>Enter your email to continue to single sign-on.</p>

      <div class="input">
        <input
          name="email"
          id="ssoEmail"
          type="text"
          placeholder="Email address"
          required={true}
          autocomplete="off"
          ref={inputRef}
          onKeyUp={() => setError("ssoCheck", null)}
          value={email}
          onInput={(event) => setEmail(event.currentTarget.value)}
        />
      </div>

      <div class="remember_email">
        <input
          type="checkbox"
          id="remember_sso_email"
          name="remember_sso_email"
          checked={rememberEmail}
          onClick={() => setRememberEmail((prev) => !prev)}
        />
        <label for="remember_sso_email">Remember my login details?</label>
      </div>

      <button
        name="ssoSignInSubmitButton"
        class="login_button"
        disabled={!!error || processing}
      >
        Continue {processing && <span class="loader ml-5" />}
      </button>

      {error && <div class="error">{error}</div>}

      <a href="" class="change_screen_link" onClick={changeScreen("index")}>
        Sign in using password
      </a>
    </form>
  );
}
