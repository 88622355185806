import {
  StateUpdater,
  useEffect,
  useCallback,
  useState,
  Dispatch,
} from "preact/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import shallow from "zustand/shallow";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { h, VNode } from "preact";
import { Tenant } from "../types";
import { useStore } from "../store";
import { getCSRFToken } from "../utils";
import Autocomplete from "../components/async/Autocomplete";

library.add(faCog);

type SelectionItemProps = {
  index: number;
  isSelected: boolean;
  setSelected: Dispatch<StateUpdater<number>>;
  title: string;
};

const SelectionItem = ({
  index,
  isSelected,
  setSelected,
  title,
}: SelectionItemProps): VNode => {
  return (
    <li
      class={isSelected ? "selected" : ""}
      onClick={() => setSelected(index)}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          setSelected(index);
        }
      }}
      tabIndex={0}
    >
      {title}
      {isSelected && (
        <div class="checkmark_container">
          <span class="checkmark" />
        </div>
      )}
    </li>
  );
};

type SelectionListProps = {
  tenants: Tenant[];
  selected: number;
  setSelectedTenant: Dispatch<StateUpdater<number>>;
  setSelectedCog: Dispatch<StateUpdater<boolean>>;
};
export const SelectionList = ({
  tenants,
  selected,
  setSelectedTenant,
  setSelectedCog,
}: SelectionListProps): VNode => {
  const [userSettingsInit, setUserSettingsInit] = useState(false);
  const { setError, userSettings, setUserSettings } = useStore(
    (state) => ({
      setError: state.setError,
      userSettings: state.userSettings,
      setUserSettings: state.setUserSettings,
    }),
    shallow
  );

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    async function fetch_user_settings() {
      const response = await fetch("/user_settings", {
        method: "GET",
        headers: {
          "X-CSRF-Token": getCSRFToken(),
        },
      });
      if (response.ok) {
        const { first_name, last_name, email, is_sso } = await response.json();
        setUserSettings({
          name: `${first_name} ${last_name}`,
          email,
          isSso: is_sso,
        });
        if (tenants.length === 0) {
          setError(
            "chooseTenant",
            "No access defined - contact your Aiwo administrator"
          );
        }
      } else {
        setError("chooseTenant", "Failed to fetch user settings!");
      }
    }
    if (!userSettingsInit) {
      setUserSettingsInit(true);
      // Clear query params to make path look nicer
      window.history.replaceState({}, "", window.location.pathname);
      fetch_user_settings();
    }
  }, [userSettingsInit, setError, setUserSettings]);

  const getTenantSelectId = useCallback(
    (value: Tenant | null) => {
      let tenantId = -1;
      if (value) {
        tenants.forEach((tenant, index) => {
          if (tenant.name === value.name) {
            tenantId = index;
          }
        });
      }

      return tenantId;
    },
    [tenants]
  );
  return (
    <div>
      <div class="selection_container" data-testid="selectionContainer">
        <div class="user">
          <div>
            <p class="username">{userSettings.name}</p>
            <p class="email">
              {userSettings.email}
              {!userSettings.name && !userSettings.email && (
                <span class="loader ml-5" />
              )}
            </p>
          </div>
          {userSettings.name && (
            <i
              class="user-settings-navigate"
              onClick={() => setSelectedCog(true)}
              title="User settings"
            >
              <FontAwesomeIcon icon="cog" />
            </i>
          )}
        </div>
        {tenants.length > 0 && (
          <div>
            <strong class="title">Select instance:</strong>
            {tenants.length <= 3 ? (
              <div>
                {tenants.map((tenant, index) => {
                  const isSelected = selected === index;
                  return (
                    <SelectionItem
                      key={null}
                      index={index}
                      isSelected={isSelected}
                      setSelected={setSelectedTenant}
                      title={tenant.name}
                    />
                  );
                })}
              </div>
            ) : (
              <Autocomplete
                inputValue={inputValue}
                setInputValue={setInputValue}
                tenants={tenants}
                setSelectedTenant={setSelectedTenant}
                getTenantSelectId={getTenantSelectId}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

type ChooseTenantProps = {
  redirectToAizait: (redirectUrl: string, envAndTenantId: string) => void;
  redirectToUserSettings: () => void;
  logout: () => void;
};

export function ChooseTenant({
  redirectToAizait,
  redirectToUserSettings,
  logout,
}: ChooseTenantProps): VNode | null {
  const { authenticationResponse, error } = useStore(
    (state) => ({
      authenticationResponse: state.responses.authentication,
      error: state.errors.chooseTenant,
    }),
    shallow
  );
  const { tenants, authenticationComplete } = authenticationResponse;
  useEffect(() => {
    // redirect straight away if only one tenant
    if (tenants.length === 1 && authenticationComplete) {
      redirectToAizait(tenants[0].redirect_url, tenants[0].env_and_tenant_id);
    }
  }, [tenants, authenticationComplete, redirectToAizait]);
  const [selectedTenant, setSelectedTenant] = useState(0);
  const [selectedCog, setSelectedCog] = useState(false);

  useEffect(() => {
    if (selectedCog) {
      redirectToUserSettings();
    }
  }, [selectedCog, redirectToUserSettings]);

  if (tenants.length === 1) {
    return null;
  }

  return (
    <div>
      {authenticationResponse.passwordChanged && (
        <div>
          <strong className="title">Password changed</strong>
          <p className="password_changed">
            Your password was changed successfully.
          </p>
        </div>
      )}
      <SelectionList
        tenants={tenants}
        selected={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        setSelectedCog={setSelectedCog}
      />

      <div class="sign_on">
        <button class="logout_button mr-5" onClick={logout}>
          Log out
        </button>

        <button
          class="continue_button ml-5"
          data-testid="continueButton"
          disabled={
            tenants.length === 0 ||
            selectedTenant < 0 ||
            selectedTenant >= tenants.length
          }
          onClick={() => {
            if (authenticationComplete) {
              redirectToAizait(
                tenants[selectedTenant].redirect_url,
                tenants[selectedTenant].env_and_tenant_id
              ),
                authenticationComplete;
            }
          }}
        >
          Continue
        </button>
      </div>
      {error && <div class="error">{error}</div>}
    </div>
  );
}
