import create, { GetState, SetState, StoreApi } from "zustand";
import { JSX } from "preact";
import {
  AuthenticateResponse,
  Challenge,
  RequestResetPasswordResponse,
  ScreenId,
  SSODetailsResponse,
  Tenant,
} from "./types";
import { getCSRFToken, setCSRFToken } from "./utils";

type ErrorType = string | null;

type LoginState = {
  activeScreen: ScreenId;
  challenge: Challenge;
  isInFrame: boolean;
  errors: {
    chooseTenant: ErrorType;
    signIn: ErrorType;
    permanentPassword: ErrorType;
    requestPasswordReset: ErrorType;
    resetPassword: ErrorType;
    ssoCheck: ErrorType;
    ssoSignIn: ErrorType;
    userSettings: ErrorType;
  };
  processing: {
    permanentPassword: boolean;
    requestPasswordReset: boolean;
    resetPassword: boolean;
    signIn: boolean;
    ssoCheck: boolean;
    userSettings: boolean;
  };
  resetPasswordUsername: string | null;
  responses: {
    authentication: {
      tenants: Tenant[];
      authenticationComplete: boolean | null;
      passwordChanged: boolean | null;
    };
    requestPasswordReset: RequestResetPasswordResponse | null;
    ssoCheck: SSODetailsResponse | null;
    userSettings: {
      passwordChanged: boolean | null;
    };
  };
  successMessages: {
    app: string | null;
  };
  userSettings: {
    name: string;
    email: string;
    isSso: boolean | null;
  };
};

type LoginActions = {
  changeScreen: (
    name: ScreenId
  ) => (event: JSX.TargetedEvent<HTMLAnchorElement, Event>) => void;
  setIsInFrame: (isInFrame: boolean) => void;
  checkSSOIdp: (idp: string) => Promise<void>;
  checkSSOEmail: (options: {
    email: string;
  }) => (event: JSX.TargetedEvent<HTMLFormElement, Event>) => Promise<void>;
  processLogin: (options: {
    username: string;
    password: string;
  }) => (event: JSX.TargetedEvent<HTMLFormElement, Event>) => Promise<void>;
  requestPasswordReset: (options: {
    username: string;
  }) => (event: JSX.TargetedEvent<HTMLFormElement, Event>) => Promise<void>;
  resetPassword: (options: {
    newPassword: string;
    confirmationCode: string;
  }) => (event: JSX.TargetedEvent<HTMLFormElement, Event>) => Promise<void>;
  setPermanentPassword: (options: {
    newPassword: string;
  }) => (event: JSX.TargetedEvent<HTMLFormElement, Event>) => Promise<void>;
  ssoAuthenticate: (
    code: string,
    session_state: string,
    state: string,
    in_frame: boolean
  ) => Promise<void>;
  resetChallenge: () => void;
  setActiveScreen: (screenId: ScreenId) => void;
  setChallenge: (challenge: Challenge) => void;
  setError: (field: keyof LoginState["errors"], error: ErrorType) => void;
  setProcessing: (
    field: keyof LoginState["processing"],
    processing: boolean
  ) => void;
  setResponse: (
    field: keyof LoginState["responses"],
    response: LoginState["responses"][keyof LoginState["responses"]]
  ) => void;
  setSuccessMessage: (
    field: keyof LoginState["successMessages"],
    message: string | null
  ) => void;
  setUserSettings: (options: {
    name: string;
    email: string;
    isSso: boolean | null;
  }) => void;
  changeUserPassword: (options: {
    currentPassword: string;
    newPassword: string;
  }) => (event: JSX.TargetedEvent<HTMLFormElement, Event>) => Promise<void>;
  clearStore: () => void;
};

const initialState: LoginState = {
  activeScreen: "none",
  challenge: {
    type: undefined,
    detail: undefined,
    session: undefined,
    username: undefined,
  },
  isInFrame: false,
  errors: {
    chooseTenant: null,
    permanentPassword: null,
    requestPasswordReset: null,
    resetPassword: null,
    signIn: null,
    ssoCheck: null,
    ssoSignIn: null,
    userSettings: null,
  },
  processing: {
    permanentPassword: false,
    requestPasswordReset: false,
    resetPassword: false,
    signIn: false,
    ssoCheck: false,
    userSettings: false,
  },
  resetPasswordUsername: null,
  responses: {
    authentication: {
      tenants: [],
      authenticationComplete: null,
      passwordChanged: null,
    },
    requestPasswordReset: null,
    ssoCheck: null,
    userSettings: {
      passwordChanged: null,
    },
  },
  successMessages: {
    app: null,
  },
  userSettings: {
    name: "",
    email: "",
    isSso: null,
  },
};

const actions: (
  set: SetState<LoginState & LoginActions>,
  get: GetState<LoginState & LoginActions>,
  api: StoreApi<LoginState & LoginActions>
) => LoginActions = (set, get) => ({
  setChallenge: (challenge: Challenge) => set({ challenge }),
  setError: (field: keyof LoginState["errors"], error: ErrorType) =>
    set({ errors: { ...get().errors, [field]: error } }),
  setProcessing: (field: keyof LoginState["processing"], processing: boolean) =>
    set({ processing: { ...get().processing, [field]: processing } }),
  setResponse: (
    field: keyof LoginState["responses"],
    response: LoginState["responses"][keyof LoginState["responses"]]
  ) => set({ responses: { ...get().responses, [field]: response } }),
  setSuccessMessage: (
    field: keyof LoginState["successMessages"],
    message: string | null
  ) => set({ successMessages: { ...get().successMessages, [field]: message } }),
  setIsInFrame: (isInFrame: boolean) => set({ isInFrame }),
  checkSSOIdp: async (idp: string) => {
    try {
      const response = await fetch(`/sso_login`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-CSRF-Token": getCSRFToken(),
        },
        body: JSON.stringify({ idp }),
      });

      const json: SSODetailsResponse = await response.json();
      if (response.ok && json) {
        get().setResponse("ssoCheck", json);
        const {
          idm_url,
          state,
          code_challenge,
          code_challenge_method,
          identity_provider_id,
        } = json;
        const redirect_uri = `${encodeURIComponent(location.origin)}/`;
        window.location.href = `${idm_url}?response_type=code&client_id=aiwo-login&state=${state}&scope=openid&redirect_uri=${redirect_uri}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&kc_idp_hint=${identity_provider_id}`;
      } else {
        get().setActiveScreen("sso_details");
      }
    } catch (err: any) {
      get().setActiveScreen("sso_details");
    }
  },
  checkSSOEmail:
    ({ email }: { email: string }) =>
    async (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
      event.preventDefault();
      get().setProcessing("ssoCheck", true);
      get().setError("signIn", "");
      get().setError("resetPassword", "");

      try {
        const response = await window.fetch("/sso_details", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-CSRF-Token": getCSRFToken(),
          },
          body: JSON.stringify({
            username: email,
          }),
        });
        const json: SSODetailsResponse = await response.json();
        if (response.ok && json) {
          window.localStorage.setItem("sso_email", email);
          get().setResponse("ssoCheck", json);
          get().setActiveScreen("sso_signin");
        } else if (response.status === 404) {
          throw new Error("No SSO details found for given email address");
        } else {
          throw new Error(
            "An unexpected error occurred during requesting password reset, please try again later."
          );
        }
      } catch (error: any) {
        get().setError("ssoCheck", error.message);
      } finally {
        get().setProcessing("ssoCheck", false);
      }
    },
  processLogin:
    ({ username, password }: { username: string; password: string }) =>
    async (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
      event.preventDefault();
      get().setProcessing("signIn", true);

      try {
        const response = await window.fetch("/authenticate", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-CSRF-Token": getCSRFToken(),
          },
          body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
          const json: AuthenticateResponse = await response.json();
          const {
            tenants,
            authentication_complete,
            requested_challenge,
            session,
            csrftoken,
          } = json;

          if (requested_challenge) {
            get().setChallenge({
              type: requested_challenge.type,
              detail: requested_challenge.detail,
              session,
              username,
            });
            get().setActiveScreen("new_password_challenge");
          } else if (authentication_complete) {
            if (csrftoken) {
              setCSRFToken(csrftoken);
            }
            get().setResponse("authentication", {
              tenants,
              authenticationComplete: authentication_complete,
              passwordChanged: false,
            });
            get().setActiveScreen("choose_tenant");
          } else {
            throw new Error(
              "An unexpected error occurred during sign in, please try again later."
            );
          }
        } else if (response.status === 400 || response.status === 401) {
          throw new Error("Invalid username or password.");
        } else if (response.status === 403) {
          const detail = (await response.json()).detail as string;
          if (detail === "SSO_USER_NOT_ALLOWED") {
            throw new Error("Please login using SSO");
          } else {
            get().setActiveScreen("choose_tenant");
          }
        } else {
          throw new Error(
            "An unexpected error occurred during sign in, please try again later."
          );
        }
      } catch (error: any) {
        get().setError("signIn", error.message);
      } finally {
        get().setProcessing("signIn", false);
      }
    },
  requestPasswordReset:
    ({ username }: { username: string }) =>
    async (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
      event.preventDefault();
      get().setProcessing("requestPasswordReset", true);
      get().setSuccessMessage("app", "");
      get().setError("signIn", "");
      get().setError("resetPassword", "");

      try {
        const response = await window.fetch("/request_password_reset", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-CSRF-Token": getCSRFToken(),
          },
          body: JSON.stringify({
            username,
          }),
        });
        const resetPasswordResponse: RequestResetPasswordResponse =
          await response.json();
        if (response.ok && resetPasswordResponse) {
          set({ resetPasswordUsername: username });
          get().setResponse("requestPasswordReset", resetPasswordResponse);
          get().setActiveScreen("password_reset");
        } else if (response.status === 429) {
          throw new Error(
            "You have tried to reset your password too many times. Try again later."
          );
        } else {
          throw new Error(
            "An unexpected error occurred during requesting password reset, please try again later."
          );
        }
      } catch (error: any) {
        get().setError("requestPasswordReset", error.message);
      } finally {
        get().setProcessing("requestPasswordReset", false);
      }
    },
  resetPassword:
    ({
      newPassword,
      confirmationCode,
    }: {
      newPassword: string;
      confirmationCode: string;
    }) =>
    async (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
      event.preventDefault();
      get().setProcessing("resetPassword", true);
      const response = get().responses.requestPasswordReset;
      const state = response?.state || "";

      try {
        const response = await window.fetch("/reset_password", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-CSRF-Token": getCSRFToken(),
          },
          body: JSON.stringify({
            state,
            username: get().resetPasswordUsername,
            password: newPassword,
            confirmation_code: confirmationCode,
          }),
        });

        const json = await response.json();

        if (response.ok && (json as boolean)) {
          get().setSuccessMessage(
            "app",
            "Your password was changed successfully. Please login using your new password."
          );
          set({ errors: { ...get().errors, resetPassword: null } });
          get().setActiveScreen("index");
        } else if (response.status === 400) {
          set({
            errors: { ...get().errors, resetPassword: json.detail },
          });
        } else if (response.status === 401) {
          throw new Error("Invalid confirmation code, please try again.");
        } else if (response.status === 429) {
          throw new Error(
            "You have tried to reset your password too many times. Try again later."
          );
        } else {
          throw new Error(
            "An unexpected error occurred during requesting password reset, please try again later."
          );
        }
      } catch (error: any) {
        get().setError("resetPassword", error.message);
      } finally {
        get().setProcessing("resetPassword", false);
      }
    },
  setUserSettings: ({
    name,
    email,
    isSso,
  }: {
    name: string;
    email: string;
    isSso: boolean | null;
  }) => {
    set({ userSettings: { ...get().userSettings, name, email, isSso } });
  },
  changeUserPassword:
    ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) =>
    async (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
      event.preventDefault();
      get().setProcessing("userSettings", true);

      try {
        const response = await fetch("/user_change_password", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-CSRF-Token": getCSRFToken(),
          },
          body: JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword,
          }),
        });

        if (response.ok) {
          get().setResponse("userSettings", {
            passwordChanged: true,
          });
          set({ errors: { ...get().errors, userSettings: null } });
        } else {
          const json = await response.json();
          set({ errors: { ...get().errors, userSettings: json.detail } });
        }
      } finally {
        get().setProcessing("userSettings", false);
      }
    },
  setPermanentPassword:
    ({ newPassword }: { newPassword: string }) =>
    async (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
      event.preventDefault();
      get().setProcessing("permanentPassword", true);

      try {
        const response = await window.fetch("/authenticate_with_challenge", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "X-CSRF-Token": getCSRFToken(),
          },
          body: JSON.stringify({
            challenge_response: newPassword,
            type: "NEW_PASSWORD",
            session: get().challenge.session,
            username: get().challenge.username,
          }),
        });
        if (response.ok) {
          const json: AuthenticateResponse = await response.json();
          const { tenants, authentication_complete, csrftoken } = json;

          if (authentication_complete) {
            if (csrftoken) {
              setCSRFToken(csrftoken);
            }
            get().setResponse("authentication", {
              tenants,
              authenticationComplete: authentication_complete,
              passwordChanged: true,
            });
            get().setActiveScreen("choose_tenant");
          } else {
            throw new Error(
              "An unexpected error occurred during password change, please try again later."
            );
          }
        } else if (response.status === 400) {
          const json = await response.json();
          get().setError("permanentPassword", json.detail);
        } else {
          throw new Error(
            "An unexpected error occurred during password change, please try again later."
          );
        }
      } catch (error: any) {
        get().setError("permanentPassword", error.message);
      } finally {
        get().setProcessing("permanentPassword", false);
      }
    },
  ssoAuthenticate: async (
    code: string,
    session_state: string,
    state: string,
    in_frame: boolean
  ) => {
    try {
      const response = await window.fetch("/sso_authenticate", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-CSRF-Token": getCSRFToken(),
        },
        body: JSON.stringify({ code, session_state, state, in_frame }),
      });

      if (response.ok) {
        const json: AuthenticateResponse = await response.json();
        const { tenants, authentication_complete, csrftoken } = json;

        if (authentication_complete) {
          if (csrftoken) {
            setCSRFToken(csrftoken);
          }
          get().setResponse("authentication", {
            tenants,
            authenticationComplete: authentication_complete,
            passwordChanged: false,
          });
          if (in_frame) {
            window.close();
          } else {
            get().setActiveScreen("choose_tenant");
          }
        } else {
          throw new Error(
            "An unexpected error occurred during single sign-on, please try again later."
          );
        }
      } else if (response.status == 403) {
        if (in_frame) {
          window.close();
        } else {
          get().setActiveScreen("choose_tenant");
        }
      } else {
        throw new Error(
          "An unexpected error occurred during single sign-on, please try again later."
        );
      }
    } catch (error: any) {
      get().setError("signIn", error.message);
    } finally {
      get().setProcessing("signIn", false);
    }
  },
  resetChallenge: () =>
    set({
      challenge: {
        type: undefined,
        detail: undefined,
        session: undefined,
        username: undefined,
      },
    }),
  setActiveScreen: (screenId: ScreenId) => set({ activeScreen: screenId }),
  changeScreen:
    (name: ScreenId) =>
    (event: JSX.TargetedEvent<HTMLAnchorElement, Event>) => {
      event.preventDefault();
      get().setActiveScreen(name);
    },
  clearStore: () => {
    set(initialState);
  },
});

export const useStore = create<LoginState & LoginActions>((set, get, api) => ({
  ...initialState,
  ...actions(set, get, api),
}));
