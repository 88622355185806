import { h, JSX, VNode } from "preact";
import shallow from "zustand/shallow";
import { useStore } from "../store";

type SSOAuthenticateProps = {
  onIndexNavigation: (
    event: JSX.TargetedEvent<HTMLAnchorElement, Event>
  ) => void;
};

export function SSOAuthenticate({
  onIndexNavigation,
}: SSOAuthenticateProps): VNode {
  const { error, processing } = useStore(
    (state) => ({
      error: state.errors.signIn,
      processing: state.processing.signIn,
    }),
    shallow
  );

  return (
    <div class="sso_container">
      {processing && <span class="loader loader_sso ml-5" />}
      {error && (
        <div>
          <div class="error error_sso">{error}</div>

          <a
            href=""
            class="change_screen_link"
            onClick={(event: JSX.TargetedEvent<HTMLAnchorElement, Event>) => {
              onIndexNavigation(event);
            }}
          >
            Sign in using password
          </a>
        </div>
      )}
    </div>
  );
}
