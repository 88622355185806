import { useEffect, useRef, useState } from "preact/hooks";
import { h, VNode } from "preact";
import shallow from "zustand/shallow";
import { useStore } from "../store";

export function SignIn(): VNode {
  const { changeScreen, error, processing, processLogin, setError } = useStore(
    (state) => ({
      changeScreen: state.changeScreen,
      error: state.errors.signIn,
      processing: state.processing.signIn,
      processLogin: state.processLogin,
      setError: state.setError,
    }),
    shallow
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <form
      action="/authenticate"
      onSubmit={processLogin({ username, password })}
    >
      <div class="input">
        <input
          name="username"
          id="signInFormUsername"
          type="text"
          placeholder="Email address"
          required={true}
          autocomplete="off"
          ref={inputRef}
          onKeyUp={() => setError("signIn", null)}
          value={username}
          onInput={(event) => setUsername(event.currentTarget.value)}
        />
      </div>

      <div class="input">
        <input
          name="password"
          id="signInFormPassword"
          type="password"
          placeholder="Password"
          required={true}
          autocomplete="off"
          onKeyUp={() => setError("signIn", null)}
          value={password}
          onInput={(event) => setPassword(event.currentTarget.value)}
        />
      </div>

      <button
        name="signInSubmitButton"
        class="login_button"
        disabled={!!error || processing}
      >
        Sign In {processing && <span class="loader ml-5" />}
      </button>

      {error && <div class="error">{error}</div>}

      <a
        href=""
        class="change_screen_link"
        onClick={changeScreen("request_password_reset")}
      >
        Forgot your password?
      </a>

      <a
        href=""
        class="change_screen_link"
        onClick={changeScreen("sso_details")}
      >
        Sign in using single sign-on (SSO)
      </a>
    </form>
  );
}
